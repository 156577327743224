export class BasicObject {
    constructor(config, canvasService) {
        this.canvasService = canvasService;
        this.relatedCells = [];
        this.isModelFlipped = false;
        this.reverseModel = false;
        this.coords = config.coords;
        this.size = config.size;
    }
    drawObject() {
        const modelCoords = [
            this.coords[0] + this.model.offset[0],
            this.coords[1] + this.model.offset[1],
            ...this.model.size
        ];
        if ((!this.isModelFlipped && !this.reverseModel) || (this.isModelFlipped && this.reverseModel))
            this.canvasService.drawImage(this.model.image, modelCoords);
        else
            this.canvasService.drawFlippedImage(this.model.image, modelCoords);
    }
}
