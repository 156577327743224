export class ConfigService {
    constructor(config) {
        this.config = this.applyDefaultConfig(config);
        this.parsedConfig = this.parseConfig(this.config);
    }
    getParsedConfig() {
        return this.parsedConfig;
    }
    parseConfig(config) {
        const parsedConfig = {
            canvas: Object.assign({}, config.canvas),
            map: {
                width: config.map.width * config.map.cellSize,
                height: config.map.height * config.map.cellSize,
                cells: {
                    size: config.map.cellSize,
                    horizontal: config.map.width,
                    vertical: config.map.height
                }
            },
            camera: this.getParsedCameraConfig(config),
            player: this.getParsedObjectConfig(config.player, config),
            objects: config.objects.map(obj => this.getParsedObjectConfig(obj, config))
        };
        return parsedConfig;
    }
    getParsedObjectConfig(obj, config) {
        const parsedConfig = {
            type: obj.type,
            coords: [obj.coords[0] * config.map.cellSize, obj.coords[1] * config.map.cellSize],
            size: [config.map.cellSize, config.map.cellSize],
            model: {
                name: obj.model,
                offset: [0, 0],
                size: [config.map.cellSize, config.map.cellSize]
            }
        };
        return parsedConfig;
    }
    getParsedCameraConfig(config) {
        const breakpoints = {
            top: getPoint(config.canvas.height, config.camera.top),
            right: getPoint(config.canvas.width, config.camera.right),
            bottom: getPoint(config.canvas.height, config.camera.bottom),
            left: getPoint(config.canvas.width, config.camera.left)
        };
        return breakpoints;
        function getPoint(x, y) {
            const [a, b] = y.split(':');
            return x / parseInt(b) * parseInt(a);
        }
    }
    /************** DEFAULT CONFIG **************/
    applyDefaultConfig(config) {
        const def = this.getDefaultConfig();
        return {
            canvas: Object.assign(Object.assign({}, def.canvas), config.canvas),
            map: Object.assign(Object.assign({}, def.map), config.map),
            camera: Object.assign(Object.assign({}, def.camera), config.camera),
            player: Object.assign({}, config.player),
            objects: [...def.objects, ...config.objects]
        };
    }
    getDefaultConfig() {
        return {
            canvas: {
                width: 900,
                height: 600
            },
            map: {
                width: 20,
                height: 10,
                cellSize: 70,
            },
            camera: {
                top: '1:4',
                right: '1:2',
                bottom: '1:4',
                left: '1:4',
            },
            player: null,
            objects: []
        };
    }
}
