import { BasicObject } from "../basic-object";
export class Wall extends BasicObject {
    constructor(config, canvasService, imageService) {
        super(config, canvasService);
        this.hasCollision = true;
        this.model = {
            image: imageService.static[config.model.name],
            offset: config.model.offset,
            size: config.model.size
        };
    }
}
