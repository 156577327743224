import CanvasService from "./services/canvas.service";
import { ImageService } from "./services/image.service";
import { ObjectService } from "./services/object.service";
import { GameLoopService } from "./services/game-loop.service";
import { ConfigService } from "./services/config.service";
export default class Platformer2D {
    constructor(canvas, config) {
        this.imageService = new ImageService();
        this.configService = new ConfigService(config);
        this.config = this.configService.getParsedConfig();
        this.canvasService = new CanvasService(canvas, this.config, this.imageService);
        this.objectService = new ObjectService(this.imageService, this.canvasService, this.config);
        this.gameLoopService = new GameLoopService(this.canvasService, this.objectService);
        this.imageService.isLoaded.then(() => this.init());
    }
    init() {
        this.gameLoopService.start();
    }
}
;
