import { abs } from "../helpers/common-methods";
import { BasicObject } from "./basic-object";
const DEFAULT_CONFIG = {
    maxSpeed: 100,
    sideAcceleration: 0.2,
    gravity: 2000,
    maxFallSpeed: 800,
    jumpPower: 800
};
export class EntityObject extends BasicObject {
    constructor(config, canvasService) {
        super(config, canvasService);
        this.vx = 0; // X-axis speed (in pixels per second)
        this.vy = 0; // Y-axis speed (in pixels per second)
        this.entityConfig = DEFAULT_CONFIG;
        this.sideButtonPressed = false;
        this.jumpButtonPressed = false;
        this.inAir = false;
        this.textures = {
            time: 0,
            stand: { image: null },
            walk: { images: null, frequent: null },
            jump: { image: null }
        };
    }
    moveRight() {
        this.sideButtonPressed = true;
        this.isModelFlipped = false;
        if (this.vx < 0)
            this.vx = -this.slowDown(this.vx);
        else
            this.vx = this.speedUp(this.vx);
        this.coords[0] = this.coords[0] + this.vx / this.fps;
    }
    moveLeft() {
        this.sideButtonPressed = true;
        this.isModelFlipped = true;
        if (this.vx > 0)
            this.vx = this.slowDown(this.vx);
        else
            this.vx = -this.speedUp(this.vx);
        this.coords[0] = this.coords[0] + this.vx / this.fps;
    }
    jump() {
        if (!this.inAir) {
            this.jumpButtonPressed = true;
            this.inAir = true;
            this.vy = this.entityConfig.jumpPower;
            this.coords[1] = this.coords[1] - this.vy / this.fps;
        }
    }
    updateState(fps) {
        this.fps = fps;
        if (!this.sideButtonPressed)
            this._stop();
        if (!this.jumpButtonPressed)
            this._fall();
        this.sideButtonPressed = false;
        this.jumpButtonPressed = false;
    }
    ;
    setEntityConfig(entityConfig) {
        this.entityConfig = Object.assign(Object.assign({}, DEFAULT_CONFIG), entityConfig);
    }
    stopFalling() {
        this.inAir = false;
        this.vy = 0;
    }
    startFalling() {
        this.vy = this.applyGravity(0);
    }
    resetSideAcceleration() {
        this.vx = 0;
    }
    /****************** Execute this if no buttons pressed *********************/
    _stop() {
        if (this.vx === 0)
            return;
        if (this.vx > 0)
            this.vx = this.slowDown(this.vx);
        else
            this.vx = -this.slowDown(this.vx);
        this.coords[0] = this.coords[0] + this.vx / this.fps;
    }
    _fall() {
        this.inAir = true;
        this.vy = this.applyGravity(this.vy);
        this.coords[1] = this.coords[1] - this.vy / this.fps;
    }
    /****************** Helpers *********************/
    applyTextures() {
        let img;
        this.textures.time += 1000 / this.fps;
        if (this.textures.time >= 1000)
            this.textures.time = 0;
        if (this.inAir) {
            img = this.textures.jump.image;
        }
        else if (this.vx != 0) {
            this.calculateTextureIndex(this.textures.walk);
            img = this.textures.walk.images[this.textures.walk.index];
        }
        else {
            img = this.textures.stand.image;
        }
        img ? this.model.image = img : null;
    }
    calculateTextureIndex(anim) {
        const frame = Math.floor(this.textures.time / (1000 / anim.frequent));
        anim.index = frame - Math.floor(frame / anim.images.length) * anim.images.length;
    }
    applyGravity(vy) {
        const ySpeed = vy - this.entityConfig.gravity / this.fps;
        if (-ySpeed > this.entityConfig.maxFallSpeed)
            return -this.entityConfig.maxFallSpeed;
        else
            return ySpeed;
    }
    speedUp(currSpeed) {
        const speed = abs(currSpeed);
        const newSpeed = speed + this.entityConfig.maxSpeed / (this.fps * this.entityConfig.sideAcceleration);
        if (newSpeed > this.entityConfig.maxSpeed)
            return this.entityConfig.maxSpeed;
        else
            return newSpeed;
    }
    slowDown(currSpeed) {
        const speed = abs(currSpeed);
        const newSpeed = speed - this.entityConfig.maxSpeed / (this.fps * this.entityConfig.sideAcceleration);
        if (newSpeed < 0)
            return 0;
        else
            return newSpeed;
    }
}
