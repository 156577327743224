export class GameLoopService {
    constructor(canvasService, objectService) {
        this.canvasService = canvasService;
        this.objectService = objectService;
        this.isGamePaused = false;
    }
    start() {
        this.isGamePaused = false;
        this.frameStart = performance.now();
        this.step();
    }
    stop() {
        this.isGamePaused = true;
    }
    // Methods that should be executed during single game frame
    frame() {
        this.canvasService.clearCanvas();
        this.objectService.renderObjects(this.fps);
        this.canvasService.drawFPS(this.fps);
    }
    step() {
        const frameEnd = performance.now();
        this.fps = Math.floor(1000 / (frameEnd - this.frameStart));
        this.frameStart = performance.now();
        if (this.fps < 25)
            this.fps = 25;
        if (this.fps > 1000)
            this.fps = 1000;
        this.frame();
        if (!this.isGamePaused)
            setTimeout(() => this.step(), 0);
    }
}
