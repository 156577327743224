import { EntityObject } from "../entity-object";
export class Player extends EntityObject {
    constructor(config, canvasService, imageService) {
        super(config, canvasService);
        this.hasCollision = true;
        this.pressedDirection = null;
        this.isLeftPressed = false;
        this.isRightPressed = false;
        this.isJumpPressed = false;
        this.heightRate = 1.2;
        this.widthRate = 0.8;
        this.setEntityConfig({
            maxSpeed: 350
        });
        const textures = imageService.players[config.model.name];
        this.textures = {
            time: 0,
            stand: { image: textures.stand },
            walk: { images: textures.walk, frequent: 6 },
            jump: { image: textures.jump }
        };
        this.coords[1] = this.coords[1] - this.size[1] * (this.heightRate - 1);
        this.size = [this.size[0] * this.widthRate, this.size[1] * this.heightRate];
        this.model = {
            image: textures.stand,
            offset: config.model.offset,
            size: [this.size[0], this.size[1]]
        };
        this.initEventListeners();
    }
    updateState(fps) {
        switch (this.pressedDirection) {
            case 'left':
                super.moveLeft();
                break;
            case 'right':
                super.moveRight();
                break;
        }
        if (this.isJumpPressed)
            super.jump();
        super.updateState(fps);
    }
    initEventListeners() {
        document.addEventListener('keydown', e => {
            switch (e.key) {
                case 'ArrowRight':
                    this.isRightPressed = true;
                    this.updateDirection();
                    break;
                case 'ArrowLeft':
                    this.isLeftPressed = true;
                    this.updateDirection();
                    break;
                case 'ArrowUp':
                    this.isJumpPressed = true;
                    break;
            }
        });
        document.addEventListener('keyup', e => {
            switch (e.key) {
                case 'ArrowRight':
                    this.isRightPressed = false;
                    this.updateDirection();
                    break;
                case 'ArrowLeft':
                    this.isLeftPressed = false;
                    this.updateDirection();
                    break;
                case 'ArrowUp':
                    this.isJumpPressed = false;
                    break;
            }
        });
    }
    updateDirection() {
        if (this.isLeftPressed && !this.isRightPressed) {
            this.pressedDirection = 'left';
        }
        else if (!this.isLeftPressed && this.isRightPressed) {
            this.pressedDirection = 'right';
        }
        else if (!this.isLeftPressed && !this.isRightPressed) {
            this.pressedDirection = null;
        }
    }
}
