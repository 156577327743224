import { abs } from "../helpers/common-methods";
import { Decoration } from "../objects/types/decoration.object";
import { Player } from "../objects/types/player.object";
import { Wall } from "../objects/types/wall.object";
import { GridService } from "./grid.service";
export class ObjectService {
    constructor(imageService, canvasService, config) {
        this.imageService = imageService;
        this.canvasService = canvasService;
        this.background_objects = [];
        this.static_objects = [];
        this.map = {
            width: config.map.width,
            height: config.map.height,
        };
        this.gridService = new GridService(config.map);
        this.init(config);
    }
    init(config) {
        this.clearObjects();
        this.player = new Player(config.player, this.canvasService, this.imageService);
        for (let obj of config.objects) {
            switch (obj.type) {
                case 'wall':
                    this.static_objects.push(new Wall(obj, this.canvasService, this.imageService));
                    break;
                case 'decoration':
                    this.background_objects.push(new Decoration(obj, this.canvasService, this.imageService));
                    break;
            }
        }
        this.static_objects.forEach(o => this.gridService.addObjectToGrid(o));
    }
    renderObjects(fps) {
        /* Background objects render */
        this.background_objects.forEach(o => o.drawObject());
        /* Static objects render */
        this.static_objects.forEach(o => o.drawObject());
        /* Player render */
        this.renderDynamicObject(this.player, fps);
        this.canvasService.updateCameraTranslation(...this.player.coords, ...this.player.size);
    }
    renderDynamicObject(object, fps) {
        object.updateState(fps);
        this.checkBounds(object);
        this.gridService.updateObjectPosition(object);
        this.checkAllCollisions(object, this.gridService.getNeighbors(object));
        object.applyTextures();
        // Round coords after all calculations to prevent visual collision
        object.coords[0] = Math.round(object.coords[0]);
        object.coords[1] = Math.round(object.coords[1]);
        object.drawObject();
    }
    clearObjects() {
        this.background_objects = [];
        this.static_objects = [];
    }
    get allObjects() {
        return [...this.static_objects];
    }
    checkBounds(obj) {
        let [x, y] = obj.coords;
        if (x < 0) {
            x = 0;
            obj.resetSideAcceleration();
        }
        else if (x + obj.size[0] > this.map.width) {
            x = this.map.width - obj.size[0];
            obj.resetSideAcceleration();
        }
        if (y < 0) {
            y = 0;
            obj.startFalling();
        }
        else if (y + obj.size[1] > this.map.height) {
            y = this.map.height - obj.size[1];
            obj.stopFalling();
        }
        obj.coords = [x, y];
    }
    checkAllCollisions(obj, objectsAround) {
        let hasOverlaps = false;
        objectsAround.forEach(o => this.checkCollision(obj, o) ? hasOverlaps = true : null);
        if (hasOverlaps)
            this.gridService.updateObjectPosition(obj);
    }
    checkCollision(o1, o2) {
        // if o1 overlaps with o2, then o1 will be moved out from o2
        // P.S. This function is hell for debugging :)
        if (o2.hasCollision === false)
            return;
        let [o1_x1, o1_y1] = o1.coords;
        const [o1_x2, o1_y2] = [o1.coords[0] + o1.size[0], o1.coords[1] + o1.size[1]];
        const [o2_x1, o2_y1] = o2.coords;
        const [o2_x2, o2_y2] = [o2.coords[0] + o2.size[0], o2.coords[1] + o2.size[1]];
        // Check horizontal overlaps
        let horizontalOverlap = false;
        const h1 = o1_x2 - o2_x1;
        const h2 = o1_x1 - o2_x2;
        if ((h1 < 0 && h2 > 0) || (h1 > 0 && h2 < 0))
            horizontalOverlap = true;
        // Check vertical overlaps
        let verticalOverlap = false;
        const v1 = o1_y2 - o2_y1;
        const v2 = o1_y1 - o2_y2;
        if ((v1 < 0 && v2 > 0) || (v1 > 0 && v2 < 0))
            verticalOverlap = true;
        // If overlaps in all axes
        if (verticalOverlap && horizontalOverlap) {
            const hOffset = abs(h1) < abs(h2) ? h1 : h2;
            const vOffset = abs(v1) < abs(v2) ? v1 : v2;
            if (abs(hOffset) < abs(vOffset)) {
                o1_x1 -= hOffset;
                o1.resetSideAcceleration();
            }
            else {
                o1_y1 -= vOffset;
                if (vOffset > 0) {
                    o1.stopFalling();
                }
                else {
                    o1.startFalling();
                }
            }
            o1.coords = [o1_x1, o1_y1];
            return true;
        }
        return false;
    }
}
